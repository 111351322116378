import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
} from "@chakra-ui/icons";

import { CgProfile } from "react-icons/cg";

const HamburgerMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={CgProfile} />}
        variant="outline"
      />
      <MenuList>
        <MenuItem>
          <b>Upgrade</b>
        </MenuItem>
        <MenuItem>Account</MenuItem>
        <MenuItem>Settings</MenuItem>
        <MenuItem>Preferences</MenuItem>
        <MenuItem>Log Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HamburgerMenu;
