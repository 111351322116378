import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Container,
  Flex,
  Spacer,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Heading,
  Grid,
  useColorMode,
  SimpleGrid,
  Button,
  Divider,
  useColorModeValue,
  extendTheme,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

import FormCard from "../../components/FormCard";
import { GoogleLogin } from "react-google-login";

const SignIn = () => {
  let history = useHistory();

  const signInWithGoogle = () => {
    history.push("/login/email");
  };
  const responseGoogle = (response) => {
    console.log(response);
  };
  return (
    <Container
      spacing={8}
      maxW="container.md"
      d={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <FormCard needButtons={false}>
        <Heading>Log In</Heading>
        <SimpleGrid mt="6" columns={1} spacing="3">
          <Button
            onClick={signInWithGoogle}
            color="currentColor"
            variant="outline"
          >
            Sign In with Email
            {/* <FaGoogle /> */}
          </Button>
        </SimpleGrid>
        <Flex align="center" color="gray.300" mt={"6"}>
          <Box flex="1">
            <Divider borderColor="currentcolor" />
          </Box>
          <Box as="span" px="3" color={"gray.600"} fontWeight="medium">
            or continue with
          </Box>
          <Box flex="1">
            <Divider borderColor="currentcolor" />
          </Box>
        </Flex>
        <SimpleGrid mt="6" columns={1} spacing="3">
          <GoogleLogin
            clientId="1051906676727-4plpv0evhmpk2ogbvesnobukh31gmdv0.apps.googleusercontent.com"
            buttonText="Sign In with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            theme="dark"
            disabled={false}
          />
        </SimpleGrid>
      </FormCard>
    </Container>
  );
};

const SignInEmail = () => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [showVerify, setShowVerify] = React.useState(false);
  const handleClickVerify = () => setShowVerify(!showVerify);
  return (
    <FormCard needButtons={false}>
      <Heading>Sign In With Email</Heading>
      <HStack justifyContent="center">
        <div>
          <div>
            <Input type="number" placeholder="Email" />
          </div>
          <div>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
        </div>
      </HStack>
      <SimpleGrid columns={2}>
        <Button>Back</Button>
        <Button>Sign In</Button>
      </SimpleGrid>
    </FormCard>
  );
};

export { SignIn, SignInEmail };
