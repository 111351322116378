import * as React from "react";
import "react-dates/initialize";
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Code,
  Container,
  Flex,
  Spacer,
  HStack,
  PinInput,
  PinInputField,
  Heading,
  Link,
  Grid,
  useColorMode,
  SimpleGrid,
  Button,
  Divider,
  useColorModeValue,
  extendTheme,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

import HamburgerMenu from "./components/HamburgerMenu";
import AccountMenu from "./components/AccountMenu";
import StickerSoupLogo from "./logo.png";
import RotatedStickerSoupLogo from "./rotated-logo.png";
import Stepper from "./components/Stepper";

/**
 * Pages
 */
import {
  Settings,
  Age,
  Address,
  AddressVerify,
  PhoneNumber,
  PhoneNumberVerify,
} from "./pages/Settings";

import { SignUp, SignUpEmail } from "./pages/SignUp";

import { SignIn, SignInEmail } from "./pages/SignIn";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

const theme = extendTheme({
  components: {
    Steps,
  },
});

const pageRoutes = [
  "/",
  "/settings",
  "/settings/age",
  "/settings/address",
  "/settings/address/verify",
  "/settings/phone",
  "/settings/phone/verify",
  "/signup",
  "/signup/email",
  "/login",
  "/login/email",
];

const Home = () => {
  return (
    <Container
      spacing={8}
      maxW="container.md"
      d={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor="white"
    >
      {/* <Logo h="40vmin" pointerEvents="none" /> */}
      <Heading>Verify Your Account</Heading>
      <Text>Almost done! Just x more steps to start getting stickers!</Text>
      <Stepper />
      <Link to={"/privacy-policy"} as={RouterLink}>
        Why do we need this information?
      </Link>
      <Heading>Links to all pages for demo purposes only</Heading>

      <VStack textAlign={"left"} alignItems="start">
        {pageRoutes.map((r) => (
          <Link to={r} as={RouterLink} width="100%">
            {r}
          </Link>
        ))}
      </VStack>
    </Container>
  );
};

const Header = () => {
  return (
    <Flex
      pt={5}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor="white"
    >
      <Box ml={4}>
        <HamburgerMenu />
      </Box>
      <Spacer />
      <RouterLink to="/">
        <img src={StickerSoupLogo} width={100} height={200} />
      </RouterLink>
      <Spacer />
      <Box mr={4}>
        <AccountMenu />
      </Box>
    </Flex>
  );
};

export const App = () => {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Box
          textAlign="center"
          fontSize="xl"
          _before={{
            backgroundImage: RotatedStickerSoupLogo,
            backgroundRepeat: "space",
            backgroundSize: "150px",
            opacity: "3%",
            content: '""',
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            zIndex: "-1",
            position: "absolute",
          }}
        >
          <Header />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            {/* signup */}
            <Route path="/signup/email">
              <SignUpEmail />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            {/* login */}
            <Route path="/login/email">
              <SignInEmail />
            </Route>
            <Route path="/login">
              <SignIn />
            </Route>
            {/* settings */}
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="/settings/age">
              <Age />
            </Route>
            <Route path="/settings/address/verify">
              <AddressVerify />
            </Route>
            <Route path="/settings/address/">
              <Address />
            </Route>
            <Route path="/settings/phone/verify">
              <PhoneNumberVerify />
            </Route>
            <Route path="/settings/phone/">
              <PhoneNumber />
            </Route>
            {/* need to add settings to change the other stuff like email
            and name and password */}
          </Switch>
        </Box>
      </ChakraProvider>
    </Router>
  );
};
