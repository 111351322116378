import * as React from "react";

import { Box, Container, SimpleGrid, Button, Link } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const FormCard = ({ nextLink, previousLink, needButtons = true, children }) => {
  let history = useHistory();

  const back = () => {
    history.push(previousLink || "/");
  };
  const next = () => {
    history.push(nextLink || "/");
  };

  const generateButtons = () => {
    if (needButtons) {
      return (
        <>
          <SimpleGrid columns={2} spacing={5}>
            <Button onClick={back}>Back</Button>
            <Button onClick={next} colorScheme="teal">
              Finish
            </Button>
          </SimpleGrid>
          <Link color="teal.500">Skip and finish later</Link>
        </>
      );
    }
  };
  return (
    <Container
      spacing={8}
      maxW="container.md"
      d={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box maxW={{ sm: "md" }} mx={{ sm: "auto" }} mt="8" w={{ sm: "full" }}>
        <SimpleGrid
          bg={"white"}
          py="8"
          px={{ base: "4", md: "10" }}
          shadow="base"
          rounded={{ sm: "lg" }}
          columns={1}
          spacing={5}
        >
          {children}
          {generateButtons()}
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default FormCard;
