import React, { useState, useRef } from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Container,
  Flex,
  Spacer,
  Checkbox,
  HStack,
  PinInput,
  PinInputField,
  Heading,
  Grid,
  useColorMode,
  SimpleGrid,
  Button,
  Divider,
  Select,
  Input,
  useColorModeValue,
  extendTheme,
} from "@chakra-ui/react";
import teal from "@material-ui/core/colors/teal";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
// import {
//   DateRangePicker,
//   SingleDatePicker,
//   DayPickerRangeController,
// } from "react-dates";
// import "react-dates/lib/css/_datepicker.css";

import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from "react-places-autocomplete";

// import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import FormCard from "../../components/FormCard";
import moment from "moment";
/***
 * Main Settings Page
 */

const Settings = () => {
  return <></>;
};

/***
 * Age Components
 */

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: teal,
  },
});

const Age = () => {
  const [selectedDate, handleDateChange] = useState();
  const handleSubmit = () => {};
  return (
    <FormCard>
      <Heading>Verify Your Age</Heading>
      <HStack justifyContent="center">
        <ThemeProvider theme={defaultMaterialTheme}>
          {/* <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}> */}
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <DatePicker
              disableFuture
              openTo="year"
              renderInput={(props) => (
                <TextField label="Date" helperText="Something" />
              )}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          {/* </MuiPickersUtilsProvider> */}
        </ThemeProvider>
      </HStack>
    </FormCard>
  );
};

/**
 * Address Components
 */

const Address = () => {
  /**
   * Hooks
   *
   */
  // entire address
  const [address, setAddress] = useState("");
  const [modified, setModified] = useState(false);

  const [primaryAddress, setPrimaryAddress] = useState("");
  const [secondaryAddress, setSecondaryAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");

  /**
   * Helper functions
   */

  /**
   * suggestions popover
   */
  const [isOpen, setIsOpen] = React.useState(false);
  const initialFocusRef = React.useRef();
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  // process geo code output and populate inputs
  const processGeoCode = (res) => {
    console.log(res);
    let tempPrimaryAddress = "";
    res[0].address_components.forEach((comp) => {
      let type = comp.types[0];
      switch (type) {
        case "street_number":
          tempPrimaryAddress += comp.long_name + " ";
          break;
        case "route":
          tempPrimaryAddress += comp.long_name;
          break;
        case "locality":
          setCity(comp.long_name);
          break;
        case "administrative_area_level_1":
          setState(comp.short_name);
          break;
        case "country":
          setCountry(comp.long_name);
          break;
        case "postal_code":
          setPostalCode(comp.long_name);
          break;
        default:
          break;
      }
    });
    setPrimaryAddress(tempPrimaryAddress);
  };

  // populate inputs on select
  const handleSelect = (addr, id, suggestion) => {
    console.log(suggestion);
    open();
    setAddress(addr);
    geocodeByPlaceId(id)
      .then((res) => processGeoCode(res))
      .catch((err) => console.error(err));
  };

  const handleAddress = (a) => {
    setAddress(a);
    setPrimaryAddress(a);
    console.log(a);
    open();
  };

  /**
   * Component JSX
   */
  return (
    <FormCard nextLink={"/settings/address/verify"}>
      <Heading>Address</Heading>
      <Text>We need to know where you live so we can send you stickers!</Text>
      <HStack justifyContent="center" textAlign="left">
        <div className="signup-wrapper">
          <SimpleGrid columns={1} spacing={5}>
            <PlacesAutocomplete
              value={primaryAddress}
              onChange={handleAddress}
              onSelect={handleSelect}
              highlightFirstSuggestion
              debounce={300}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div className="autocomplete-dropdown-container">
                    <Popover
                      placement="bottom"
                      isOpen={isOpen}
                      onClose={close}
                      initialFocusRef={initialFocusRef}
                    >
                      <PopoverTrigger>
                        <Input
                          {...getInputProps({
                            placeholder: "Address",
                            className: "location-search-input",
                          })}
                          ref={initialFocusRef}
                        />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverHeader fontWeight="semibold">
                          Suggestions
                        </PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          {loading && <div>Loading...</div>}
                          {suggestions.length > 0 ? open() : close()}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <div>
              <Input
                type="text"
                placeholder="Apartment, suite, etc. (optional)"
                value={secondaryAddress}
              />
            </div>
            <HStack justifyContent="center">
              <div>
                <Input type="text" placeholder="City" value={city} />
              </div>
              <div>
                <Select name="state" required value={state}>
                  <option value="" disabled selected>
                    State
                  </option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Select>
              </div>
            </HStack>
            <div>
              <Input
                type="number"
                placeholder="Postal Code"
                value={postalCode}
              />
            </div>
            <Text>
              Invalid address? Shoot us an email and we'll see if we can sort
              things out!
            </Text>
          </SimpleGrid>
        </div>
      </HStack>
    </FormCard>
  );
};

const AddressVerify = () => {
  return (
    <FormCard previousLink={"/settings/address"}>
      <Heading>Verify Your Address</Heading>
      <iframe
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDQCGo5xWpveHyOHVKUw6HI9Jirve3qI-I&q=Clarksburg,Maryland"
        allowfullscreen=""
        width="100%"
        height="500px"
        loading="lazy"
      ></iframe>
    </FormCard>
  );
};

/**
 * Phone Number Components
 */
const PhoneNumber = () => {
  return (
    <FormCard nextLink={"/settings/phone/verify"}>
      <Heading>Enter Phone Number</Heading>
      <Text>
        Enter your phone number to receive a verification code via text message.
      </Text>
      <Text mb="8px">Phone Number</Text>
      <Input placeholder="(123) - 456 - 7890" size="sm" />
      <Text>Don't have a phone number?</Text>
      <Checkbox>I agree to allow message...</Checkbox>
    </FormCard>
  );
};
const PhoneNumberVerify = () => {
  return (
    <FormCard previousLink={"/settings/phone"}>
      <Heading>Verify Phone Number</Heading>
      <Text>
        Enter the 6-digit code we just sent to xxx-xxx-8945 to verify your
        phone!
      </Text>
      <HStack justifyContent="center">
        <PinInput placeholder={"_"}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Text>Didn't get the verification code?</Text>
    </FormCard>
  );
};
export {
  Settings,
  Age,
  Address,
  AddressVerify,
  PhoneNumber,
  PhoneNumberVerify,
};
