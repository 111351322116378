import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  AddIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
} from "@chakra-ui/icons";

import { Link } from "react-router-dom";

const HamburgerMenu = () => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  if (text == "light") {
    toggleColorMode();
  }
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        <Link to="/">
          <MenuItem>Home</MenuItem>
        </Link>
        <MenuItem>About</MenuItem>
        <MenuItem>Blog</MenuItem>
        <MenuItem>Plans</MenuItem>
        <MenuItem>Shop</MenuItem>
        <MenuItem>Help</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HamburgerMenu;
