import React from "react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Code,
  Flex,
  Spacer,
  Grid,
  Button,
  theme,
} from "@chakra-ui/react";

import { Link, useHistory } from "react-router-dom";

const content = (
  <Flex textAlign="left" py={2} px={4}>
    <div>asdf</div>
  </Flex>
);

const steps = [
 
  { label: "Verify Age", route: "/settings/age" },
  { label: "Verify Address", route: "/settings/address" },
  { label: "Verify Phone Number", route: "/settings/phone" },
];



const Vertical = (): JSX.Element => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 1,
  });
  let history = useHistory();
  
  const pushHistory = (r: string) => {
    history.push(r);
  };
  
  const activeStepProps= {
    description: "click to edit",
    cursor:"pointer",

  }
  return (
    <VStack width="100%">
      <Steps orientation="vertical" activeStep={activeStep}>
        <Steps activeStep={activeStep}>
          {steps.map(({ label, route }, i) => (
            <Step
              label={label}
              key={label}
              onClick={() => {
                if (i <= activeStep)
                  pushHistory(route);
              }}
              _hover={{backgroundColor:"grey.500"}}
              transition="background-color 0.5s ease"
              description={i<activeStep?"click to edit":""}
              cursor={i<=activeStep?"pointer":""}
            >
             {activeStep == i && (<Button>Complete</Button>)}
            </Step>
          ))}
        </Steps>
      </Steps>
    </VStack>
  );
};

export default Vertical;
